import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { languageState, text } from '../../utils/state';

export default function MezclaXStems() {
	const languageRecoil = useRecoilValue(languageState);
	const textRecoil = useRecoilValue(text);

	return (
		<div className="services_section">
			<p>{textRecoil[languageRecoil].services.stems.description}</p>
			<table className="services_table">
				<tbody>
					<tr>
						<td>
							6 stems{' '}
							<span style={{ fontSize: '.8rem' }}>
								(4 + {textRecoil[languageRecoil].services.stems.kick} &{' '}
								{textRecoil[languageRecoil].services.stems.snare})
							</span>
						</td>
						<td>40€</td>
					</tr>
					<tr>
						<td>
							6 - 12 stems{' '}
							<span style={{ fontSize: '.8rem' }}>
								(+ {textRecoil[languageRecoil].services.stems.kick} &{' '}
								{textRecoil[languageRecoil].services.stems.snare})
							</span>
						</td>
						<td>60€</td>
					</tr>
					<tr>
						<td>+12</td>
						<td>
							{textRecoil[languageRecoil].services.stems.recommend_1}{' '}
							<Link to="/contacto">{textRecoil[languageRecoil].services.stems.recommend_2}</Link>{' '}
							{textRecoil[languageRecoil].services.stems.recommend_3}.
						</td>
					</tr>
					<tr>
						<td>Master x stems</td>
						<td>20€</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}
