import { useState, useEffect, useRef } from 'react';
import emailjs from 'emailjs-com';
import { useRecoilValue } from 'recoil';
import { languageState, text } from '../utils/state';

export default function Contacto() {
	const languageRecoil = useRecoilValue(languageState);
	const textRecoil = useRecoilValue(text);

	const [ message, setMessage ] = useState('');
	const [ color, setColor ] = useState('');
	const form = useRef();

	useEffect(
		() => {
			if (message !== '') {
				setTimeout(() => {
					setMessage('');
				}, 2000);
			}
		},
		[ message ]
	);

	const sendEmail = (e) => {
		e.preventDefault();
		emailjs.sendForm('contact_service', 'contact_form', form.current, 'user_A5gwogHIU6UK2F9H9TiNP').then(
			(result) => {
				console.log(result);
				setMessage('Email sent successfully');
				setColor('green');
			},
			(error) => {
				console.log(error);
				setMessage('Something went wrong');
				setColor('red');
			}
		);
	};

	return (
		<div className="contacto_page page">
			<section className="contacto contacto_section">
				<form ref={form} onSubmit={sendEmail}>
					<h4>{textRecoil[languageRecoil].contact.title2}</h4>
					<label>{textRecoil[languageRecoil].contact.name}*: </label>
					<input required={true} />
					<label>{textRecoil[languageRecoil].contact.mail}*: </label>
					<input required={true} />
					<label>{textRecoil[languageRecoil].contact.band}: </label>
					<input />
					<label>{textRecoil[languageRecoil].contact.message}*: </label>
					<textarea
						placeholder={`${textRecoil[languageRecoil].contact.placeholder_1}: - ${textRecoil[
							languageRecoil
						].contact.placeholder_2} - ${textRecoil[languageRecoil].contact.placeholder_3} - ${textRecoil[
							languageRecoil
						].contact.placeholder_4}?
            `}
						required={true}
					/>
					<p className="message" style={{ color }}>
						{message}
					</p>
					<button className="contacto_btn">{textRecoil[languageRecoil].contact.button}</button>
				</form>
			</section>
		</div>
	);
}
