import { atom } from 'recoil';

export const languageState = atom({
	key: 'languageState',
	default: 'spanish'
});

export const text = atom({
	key: 'text',
	default: {
		english: {
			navbar: {
				us: 'Us',
				services: 'Services',
				work: 'Work',
				contact: 'Contact'
			},
			home: {
				title_1: 'Mix',
				title_2: 'Mix x stems',
				title_3: 'Mastering',
				title_4: 'Recording',
				title_5: 'Production',
				title_6: 'Advisory'
			},
			us: {
				1: 'Hi Fi Noise started in 2019 created by',
				2: 'After working in their own projects and helping friends, these two sound engineers decided to open a studio to work on the sound of new projects and their respective productions and mixes.',
				3: 'Our obsession and understanding for investigating sound is what you need to find the sound experience you’ve been looking for. In',
				4: 'your songs will change in the way they need to be of professional and competitive quality which will invite the listener to feel the emotions that you want to display in your songs.'
			},
			work: {
				top: 'In HiFi Noise we have perfected and improved the sound of different artists.',
				bottom: 'Listen to our work'
			},
			services: {
				title: 'Services',
				mix: {
					description:
						'In order to offer the best sound experience to the listener, it is best to work with all the tracks of your project. During this process, the goal is to add color, balance dynamics and provide effects in order to reach perfection with your song.',
					tracks: 'tracks',
					presu: "Let's check the budget in"
				},
				stems: {
					description:
						'This type of mixing is perfect for those who want a professional touch to their mixes. This mixing service makes a difference by being competitive in today’s streaming services. Our goal is to create a final mix with energy and strength, without ruining the dynamics. Intended for you to provide us with the groups of sounds, stems of your project, such as drums, basses, voices, guitars, etc. We always order individual snare and kick tracks as well.',
					kick: 'kick',
					snare: 'snare',
					recommend_1: 'We recommend to',
					recommend_2: 'contact us',
					recommend_3: 'for a personalized budget'
				},
				master: {
					description:
						'This process is extremely important for your song to be at appropriate volume levels, Loudness is what the entire music industry is based on. We seek the maximum strength and sensation of volume possible without losing the strength and impact that the mix already has.',
					home: 'Master + Mix with us',
					external: 'External Master'
				},
				asesoria: {
					description:
						'Are you mixing or producing your own song? Any problem that you can’t solve? This  service is perfect for these type of situations, we will help  and teach you what you need. As well as checking that there is no error that has been overlooked by listening to it in on another monitoring system. You can book a session from',
					action: 'Ok, I’ve finished my album recording, NOW WHAT?',
					market_1:
						'Most emerging artists are at a loss when they have finished recording their firsts projects. The songs sound amazing, the project is at its best, but how can we generate interest from the audience in an era where social media monopolizes all space for music promotion?',
					market_2:
						'At HiFi Noise, we offer personalized advice for artists and bands who want to know more about the music industry. How do I upload my songs to Spotify, Apple Music, Shazam… and other music platforms? Should I release a single, an EP or a LP? How to get real engagement with my audience? Or more importantly, who is really my target audience that I should address as an artist? How do I start a promotional campaign for my musical project? How do I make a profit with my music? How do I extract the profits from my music and transfer them to a bank account? At HiFi Noise we have been helping artists improve their numbers and potential for quite sotime by collaborating with labels, festivals and artists. In your personalized advice session for your project, we will help you with all these questions you have and many more. You can book a session from Contact.',
					precio: 'Price 1h'
				}
			},
			contact: {
				title: 'Contact',
				title2: 'Contact with us',
				name: 'Name',
				mail: 'Email',
				band: 'Artist/Band name',
				message: 'Message',
				placeholder_1: 'If you would like a personalized budget we need some information first',
				placeholder_2: 'How many songs',
				placeholder_3: 'how many tracks in every song',
				placeholder_4: 'Will you want to master the songs with us',
				button: 'Send Email'
			}
		},
		spanish: {
			navbar: {
				us: 'Nosotros',
				services: 'Servicios',
				work: 'Trabajos',
				contact: 'Contacto'
			},
			home: {
				title_1: 'Mezcla',
				title_2: 'Mezcla x stems',
				title_3: 'Mastering',
				title_4: 'Grabación',
				title_5: 'Producción',
				title_6: 'Asesoría'
			},
			us: {
				1: 'HiFi Noise Studio empezó en 2019 de la mano de',
				2: 'Estos dos técnicos de sonido tras llevar más de dos años trabajando en proyectos propios y de amigos abrieron sus puertas para trabajar en nuevas mezclas y producciones.',
				3: 'A través de esta obsesión por el sonido casi enfermiza, en',
				4: 'te ayudamos a encontrar esa experiencia sonora que tanto deseas y has buscado durante años. Tus canciones darán ese vuelco que necesitan, y llevarán al oyente a sentir esas emociones que quieres mostrar en tus canciones!'
			},
			work: {
				top: 'En HiFi Noise hemos perfeccionado y mejorado el sonido de diferentes artistas.',
				bottom: 'Aquí todos nuestros trabajos'
			},
			services: {
				title: 'Servicios',
				mix: {
					description:
						'Con tal de ofrecer la mejor experiencia sonora al oyente lo mejor es trabajar con el total de pistas de tu proyecto. Durante este proceso, el objetivo es dar color, balancear dinámicas y aportar efectos con tal de encontrar la perfección para tu canción.',
					tracks: 'pistas',
					presu: 'Consultar presupuesto en'
				},
				stems: {
					description:
						'Este tipo de mixing es perfecto para aquellos que quieren un toque profesional a sus mezclas. Este servicio de mezcla marca la diferencia al ser competitivo en los servicios de streaming de hoy en día. Nuestro objetivo es crear una mezcla final con energía y fuerza, sin arruinar la dinámica. Destinado a que nos proporcioneis los grupos de sonidos, “stems” de vuestro proyecto, como baterías, bajos, voces, guitarras, etc. Siempre pedimos además pistas individuales de caja y bombo.',
					kick: 'bombo',
					snare: 'caja',
					recommend_1: 'Recomendamos',
					recommend_2: 'contactar',
					recommend_3: 'para presupuesto ajustado'
				},
				master: {
					description:
						'Este proceso es extremadamente importante con tal de que tu canción esté en los niveles de volumen, “Loudness”, en los que se basa toda la industria. Buscamos la máxima fuerza y sensación de volumen posible, siempre sin perder esa fuerza e impacto que ya tiene la mezcla.',
					home: 'Master con mix en esta casa',
					external: 'Master externos'
				},
				asesoria: {
					description:
						'¿Estás mezclando o produciendo tu propio tema? ¿Algún problema que no sepas solucionar? Este servicio es perfecto para estos casos, te ayudamos y enseñamos aquello que necesites. Así como comprobar que no haya ningún error que se haya pasado por alto escuchandolo en nuestro sistema de monitorización. Puedes reservar una sesión desde',
					action: 'Ok, I’ve finished my album recording, NOW WHAT?',
					market_1:
						'Most emerging artists are at a loss when they have finished recording their firsts projects. The songs sound amazing, the project is at its best, but how can we generate interest from the audience in an era where social media monopolizes all space for music promotion?',
					market_2:
						'At HiFi Noise, we offer personalized advice for artists and bands who want to know more about the music industry. How do I upload my songs to Spotify, Apple Music, Shazam… and other music platforms? Should I release a single, an EP or a LP? How to get real engagement with my audience? Or more importantly, who is really my target audience that I should address as an artist? How do I start a promotional campaign for my musical project? How do I make a profit with my music? How do I extract the profits from my music and transfer them to a bank account? At HiFi Noise we have been helping artists improve their numbers and potential for quite sotime by collaborating with labels, festivals and artists. In your personalized advice session for your project, we will help you with all these questions you have and many more. You can book a session from Contact.',
					precio: 'Precio 1 hora'
				}
			},
			contact: {
				title: 'Contacta',
				title2: 'Contacta con nosotros',
				name: 'Nombre',
				mail: 'Mail',
				band: 'Nombre del artista/grupo',
				message: 'Mensaje',
				placeholder_1: 'Para poder hacerte un presupuesto a medida necesitamos',
				placeholder_2: 'Cantidad de canciones a mezclar',
				placeholder_3: 'Cantidad de pistas o stems de cada canción',
				placeholder_4: 'Querrás masterizarlas con nosotros',
				button: 'Enviar email'
			}
		}
	}
});
