import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { languageState, text } from '../../utils/state';

export default function Asesoria() {
	const languageRecoil = useRecoilValue(languageState);
	const textRecoil = useRecoilValue(text);

	return (
		<div className="services_section">
			<p>
				{textRecoil[languageRecoil].services.asesoria.description}{' '}
				<Link to="/contacto">{textRecoil[languageRecoil].navbar.contact}</Link>.
			</p>
			<h3 style={{ textDecoration: 'underline', marginBottom: '2rem', cursor: 'default' }}>
				{textRecoil[languageRecoil].services.asesoria.action}
			</h3>
			<p>{textRecoil[languageRecoil].services.asesoria.market_1}</p>
			<table className="services_table">
				<tbody>
					<tr>
						<td>{textRecoil[languageRecoil].services.asesoria.precio}</td>
						<td>20€</td>
					</tr>
					<tr>
						<td>30min</td>
						<td>15€</td>
					</tr>
				</tbody>
			</table>
			<p>{textRecoil[languageRecoil].services.asesoria.market_2}</p>
		</div>
	);
}
