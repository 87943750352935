import Mix from './Mix';
import MezclaXStems from './MezclaXStems';
import Mastering from './Mastering';
import Asesoria from './Asesoria';
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel
} from 'react-accessible-accordion';
import { useRecoilValue } from 'recoil';
import { languageState, text } from '../../utils/state';

// import 'react-accessible-accordion/dist/fancy-example.css';

export default function Services() {
	const languageRecoil = useRecoilValue(languageState);
	const textRecoil = useRecoilValue(text);

	const items = [ 'Mix', 'Mezcla x stems', 'Mastering', 'Asesoría' ];
	const content = [ <Mix />, <MezclaXStems />, <Mastering />, <Asesoria /> ];

	return (
		<div className="services page">
			<h1>{textRecoil[languageRecoil].services.title}</h1>
			<Accordion allowZeroExpanded>
				{items.map((item, i) => (
					<AccordionItem key={i}>
						<AccordionItemHeading>
							<AccordionItemButton>{item}</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel>{content[i]}</AccordionItemPanel>
					</AccordionItem>
				))}
			</Accordion>
		</div>
	);
}
