import { useRecoilValue } from 'recoil';
import { languageState, text } from '../../utils/state';

export default function Mastering() {
	const languageRecoil = useRecoilValue(languageState);
	const textRecoil = useRecoilValue(text);

	return (
		<div className="services_section">
			<p>{textRecoil[languageRecoil].services.master.description}</p>
			<table className="services_table">
				<tbody>
					<tr>
						<td />
						<td>20€ {textRecoil[languageRecoil].services.master.home}</td>
					</tr>
					<tr>
						<td />
						<td>30€ {textRecoil[languageRecoil].services.master.external}</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}
