import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { languageState, text } from '../../utils/state';

export default function Mix() {
	const languageRecoil = useRecoilValue(languageState);
	const textRecoil = useRecoilValue(text);

	return (
		<div className="services_section">
			<p>{textRecoil[languageRecoil].services.mix.description}</p>
			<table className="services_table">
				<tbody>
					<tr>
						<td>10 {textRecoil[languageRecoil].services.mix.tracks}</td>
						<td>50€</td>
					</tr>
					<tr>
						<td>11-42 {textRecoil[languageRecoil].services.mix.tracks}</td>
						<td>80€</td>
					</tr>
					<tr>
						<td>43-72 {textRecoil[languageRecoil].services.mix.tracks}</td>
						<td>100€</td>
					</tr>
					<tr>
						<td>+72 {textRecoil[languageRecoil].services.mix.tracks}</td>
						<td>
							{textRecoil[languageRecoil].services.mix.presu}{' '}
							<Link to="/contacto">{textRecoil[languageRecoil].navbar.contact}</Link>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}
