import { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import mix from '../Resources/mix.svg';
import stems from '../Resources/stems.svg';
import master from '../Resources/master.svg';
import produccion from '../Resources/produccion.svg';
import grabacion from '../Resources/grabacion.svg';
import asesoria from '../Resources/asesoria.svg';
import foto1 from '../Resources/pics/_A6A9846.jpg';
import foto2 from '../Resources/pics/_A6A9858.jpg';
import foto3 from '../Resources/pics/_A6A9833.jpg';
import emailjs from 'emailjs-com';
import { useRecoilValue } from 'recoil';
import { languageState, text } from '../utils/state';

export default function Home(props) {
	const languageRecoil = useRecoilValue(languageState);
	const textRecoil = useRecoilValue(text);

	const [ message, setMessage ] = useState('');
	const [ color, setColor ] = useState('');
	const form = useRef();

	useEffect(
		() => {
			if (message !== '') {
				setTimeout(() => {
					setMessage('');
				}, 2000);
			}
		},
		[ message ]
	);

	const sendEmail = (e) => {
		e.preventDefault();
		emailjs.sendForm('contact_service', 'contact_form', form.current, 'user_A5gwogHIU6UK2F9H9TiNP').then(
			(result) => {
				console.log(result);
				setMessage('Email sent successfully');
				setColor('green');
			},
			(error) => {
				console.log(error);
				setMessage('Something went wrong');
				setColor('red');
			}
		);
	};

	return (
		<div className="page">
			<div className="welcome_div">
				<img loading="lazy" className="welcome_img" src={foto1} alt="promo" />
				<h1>Hi-Fi Noise Studio</h1>
				<h2>Where noise meets beauty</h2>
			</div>
			<section className="top_banner flexCenter" />
			<img loading="lazy" className="medium_banner_img medium_banner_img2" src={foto2} alt="promo" />
			<section className="medium_banner flexCenter">
				<Link to="/services/" className="flexColumn icon_card">
					<img loading="lazy" src={mix} alt="logo" />
					<h3>{textRecoil[languageRecoil].home.title_1}</h3>
				</Link>
				<Link to="/services/mezclaxstems" className="flexColumn icon_card">
					<img loading="lazy" src={stems} alt="logo" />
					<h3>{textRecoil[languageRecoil].home.title_2}</h3>
				</Link>
				<Link to="/services/mastering" className="flexColumn icon_card">
					<img loading="lazy" src={master} alt="logo" />
					<h3>{textRecoil[languageRecoil].home.title_3}</h3>
				</Link>
			</section>
			<img loading="lazy" className="medium_banner_img" src={foto3} alt="promo" />
			<section className="medium_banner flexCenter">
				<Link to="/services/" className="flexColumn icon_card">
					<img loading="lazy" src={grabacion} alt="logo" />
					<h3>{textRecoil[languageRecoil].home.title_4}</h3>
				</Link>
				<Link to="/services/mezclaxstems" className="flexColumn icon_card">
					<img loading="lazy" src={produccion} alt="logo" />
					<h3>{textRecoil[languageRecoil].home.title_5}</h3>
				</Link>
				<Link to="/services/asesoria" className="flexColumn icon_card">
					<img loading="lazy" src={asesoria} alt="logo" />
					<h3>{textRecoil[languageRecoil].home.title_6}</h3>
				</Link>
			</section>
			<section className="contacto">
				<form ref={form} onSubmit={sendEmail}>
					<h4>{textRecoil[languageRecoil].contact.title}</h4>
					<label>{textRecoil[languageRecoil].contact.name}*: </label>
					<input required={true} name="from_name" />
					<label>{textRecoil[languageRecoil].contact.mail}*: </label>
					<input required={true} name="reply_to" />
					<label>{textRecoil[languageRecoil].contact.band}: </label>
					<input name="from_band" />
					<label>{textRecoil[languageRecoil].contact.message}*: </label>
					<textarea
						placeholder={`${textRecoil[languageRecoil].contact.placeholder_1}: - ${textRecoil[
							languageRecoil
						].contact.placeholder_2} - ${textRecoil[languageRecoil].contact.placeholder_3} - ${textRecoil[
							languageRecoil
						].contact.placeholder_4}?
            `}
						required={true}
						name="message"
					/>
					<p className="message" style={{ color }}>
						{message}
					</p>
					<button>{textRecoil[languageRecoil].contact.button}</button>
				</form>
			</section>
		</div>
	);
}
