import React from 'react';
import img1 from '../Resources/pics/_A6A9889.jpg';
import img2 from '../Resources/pics/_A6A9927.jpg';
import ImageViewer from 'react-simple-image-viewer';
import { useRecoilValue } from 'recoil';
import { languageState, text } from '../utils/state';

export default function About() {
	const languageRecoil = useRecoilValue(languageState);
	const textRecoil = useRecoilValue(text);

	const [ isViewerOpen, setIsViewerOpen ] = React.useState(false);
	const [ index, setIndex ] = React.useState(0);

	const openViewer = (idx) => {
		setIsViewerOpen(true);
		setIndex(idx);
	};

	return (
		<div className="nosotros page">
			<h1>Nosotros 🫂👨‍👦</h1>
			<div className="nosotros_grid2">
				<div>
					<div>
						{textRecoil[languageRecoil].us[1]} <b>Alex Ruiz y Guille Galve</b>.{' '}
						{textRecoil[languageRecoil].us[2]}
					</div>
					<div>
						{textRecoil[languageRecoil].us[3]} <b>HiFi Noise Studio</b> {textRecoil[languageRecoil].us[4]}
					</div>
				</div>
				<div className="us_img_top">
					<img loading="lazy" src={img1} alt="img" onClick={() => openViewer(0)} />
					<div className="img_viewer">
						{isViewerOpen && (
							<ImageViewer
								src={[ img1, img2 ]}
								currentIndex={index}
								disableScroll={false}
								closeOnClickOutside={true}
								onClose={() => setIsViewerOpen(false)}
							/>
						)}
					</div>
				</div>
			</div>
			<div className="nosotros_carousel_container">
				<img loading="lazy" src={img2} alt="img" onClick={() => openViewer(1)} />
			</div>
		</div>
	);
}
