/** @jsxRuntime classic /
/* @jsx jsx */
import { jsx } from '@emotion/react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { languageState, text } from '../utils/state';

export default function Header() {
	const [ openMenu, setOpenMenu ] = useState(false);

	return (
		<div>
			<MobileNavBar openMenu={openMenu} setOpenMenu={() => setOpenMenu(!openMenu)} />
			<DesktopNavBar />
		</div>
	);
}

const DesktopNavBar = () => {
	const languageRecoil = useRecoilValue(languageState);
	const setLanguageRecoil = useSetRecoilState(languageState);
	const textRecoil = useRecoilValue(text);

	return (
		<nav className="desktop_navbar">
			<div className="navbar_left">
				<NavLink to="/" className="header_logo">
					<h3>HFN</h3>
				</NavLink>
			</div>
			<div className="navbar_right">
				<NavLink activeClassName="active" to="/about">
					{textRecoil[languageRecoil].navbar.us}
				</NavLink>
				<NavLink activeClassName="active" to="/services">
					{textRecoil[languageRecoil].navbar.services}
				</NavLink>
				<NavLink activeClassName="active" to="/trabajos">
					{textRecoil[languageRecoil].navbar.work}
				</NavLink>
				<NavLink activeClassName="active" to="/contacto">
					{textRecoil[languageRecoil].navbar.contact}
				</NavLink>
				<Toggle
					changeLanguage={() => setLanguageRecoil(languageRecoil === 'spanish' ? 'english' : 'spanish')}
					languageRecoil={languageRecoil}
				/>
			</div>
		</nav>
	);
};

const MobileNavBar = ({ openMenu, setOpenMenu }) => {
	return (
		<nav className="mobile_navbar">
			{!openMenu ? <Burguer setOpenMenu={setOpenMenu} /> : <Menu setOpenMenu={setOpenMenu} />}
		</nav>
	);
};

const Burguer = ({ setOpenMenu }) => {
	return (
		<div className="burger" onClick={setOpenMenu}>
			<img
				loading="lazy"
				alt="svgImg"
				src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIKdmlld0JveD0iMCAwIDE3MiAxNzIiCnN0eWxlPSIgZmlsbDojMDAwMDAwOyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJub256ZXJvIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgc3Ryb2tlLWxpbmVjYXA9ImJ1dHQiIHN0cm9rZS1saW5lam9pbj0ibWl0ZXIiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLWRhc2hhcnJheT0iIiBzdHJva2UtZGFzaG9mZnNldD0iMCIgZm9udC1mYW1pbHk9Im5vbmUiIGZvbnQtd2VpZ2h0PSJub25lIiBmb250LXNpemU9Im5vbmUiIHRleHQtYW5jaG9yPSJub25lIiBzdHlsZT0ibWl4LWJsZW5kLW1vZGU6IG5vcm1hbCI+PHBhdGggZD0iTTAsMTcydi0xNzJoMTcydjE3MnoiIGZpbGw9Im5vbmUiPjwvcGF0aD48ZyBmaWxsPSIjZmZmZmZmIj48cGF0aCBkPSJNMTQuMzMzMzMsMzUuODMzMzN2MTQuMzMzMzNoMTQzLjMzMzMzdi0xNC4zMzMzM3pNMTQuMzMzMzMsNzguODMzMzN2MTQuMzMzMzNoMTQzLjMzMzMzdi0xNC4zMzMzM3pNMTQuMzMzMzMsMTIxLjgzMzMzdjE0LjMzMzMzaDE0My4zMzMzM3YtMTQuMzMzMzN6Ij48L3BhdGg+PC9nPjwvZz48L3N2Zz4="
			/>
		</div>
	);
};

const Menu = ({ setOpenMenu }) => {
	const languageRecoil = useRecoilValue(languageState);
	const setLanguageRecoil = useSetRecoilState(languageState);

	return (
		<div className="menu" onClick={setOpenMenu}>
			<img
				loading="lazy"
				src="https://img.icons8.com/fluency-systems-regular/48/000000/x.png"
				style={{ filter: 'invert(100%)', height: '20px', marginBottom: '20px' }}
				alt="burger"
			/>
			<NavLink activeClassName="active2" exact to="/">
				Home
			</NavLink>
			<NavLink activeClassName="active2" to="/about">
				Nosotros
			</NavLink>
			<NavLink activeClassName="active2" to="/services">
				Servicios
			</NavLink>
			<NavLink activeClassName="active2" to="/trabajos">
				Trabajos
			</NavLink>
			<NavLink activeClassName="active2" to="/contacto">
				Contacto
			</NavLink>
			<Toggle
				changeLanguage={() => setLanguageRecoil(languageRecoil === 'spanish' ? 'english' : 'spanish')}
				languageRecoil={languageRecoil}
			/>
		</div>
	);
};

const Toggle = (props) => {
	return (
		<div
			css={toggle}
			onClick={() => props.changeLanguage()}
			style={{
				// props.location.pathname === '/xlr+' ? '#ddffc9' :
				color: 'black',
				// props.location.pathname === '/xlr+' ? 'black' :
				backgroundColor: 'white'
			}}
		>
			<span className="rotate90" style={{ opacity: props.languageRecoil === 'spanish' ? 1 : 0 }}>
				EN
			</span>
			<span className="rotate90" style={{ opacity: props.languageRecoil === 'spanish' ? 0 : 1 }}>
				ES
			</span>
		</div>
	);
};

const toggle = {
	borderRadius: '100px',
	width: '30px',
	height: '30px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	cursor: 'pointer',
	transition: 'all linear 200ms',
	webkitTouchCallout: 'none',
	webkitUserSelect: 'none',
	khtmlUserSelect: 'none',
	mozUserSelect: 'none',
	msUserSelect: 'none',
	userSelect: 'none',
	span: {
		margin: '0 0.1em 0 0',
		fontSize: '14px',
		position: 'absolute',
		transition: 'all linear 200ms',
		outline: 'none'
	}
};
