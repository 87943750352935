import { useEffect } from "react";

export default function ScrollToTop(props) {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 350);
  }, [props.location.pathname]);

  return null;
}
