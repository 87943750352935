import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./Styles/styles.css";
import "./Styles/responsive.css";
import "./Styles/mobile.css";
import { BrowserRouter, Route } from "react-router-dom";
import { RecoilRoot } from 'recoil';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <RecoilRoot>
        <Route
          path="/"
          render={(props) => <App {...props} />}
          key={Math.random()}
        />
      </RecoilRoot>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
