import React from 'react';
import { useRecoilValue } from 'recoil';
import { languageState, text } from '../utils/state';

export default function Trabajos() {
	const languageRecoil = useRecoilValue(languageState);
	const textRecoil = useRecoilValue(text);

	return (
		<div className="page trabajos">
			<h1>TRABAJOS</h1>
			<div className="flexColumn spotiEmbed">
				<p>
					{textRecoil[languageRecoil].work.top}
					<br />
					{textRecoil[languageRecoil].work.bottom}:
				</p>
				<iframe
					src="https://open.spotify.com/embed/playlist/7BqOHvWicOd0i1XdW7OEf7"
					width="60%"
					height="380"
					frameBorder="0"
					allowtransparency="true"
					allow="encrypted-media"
					title="spotify playlist"
				/>
			</div>
		</div>
	);
}
