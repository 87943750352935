import { Switch, Route } from "react-router-dom";
import Header from "./Components/Header";
import Home from "./Components/Home";
import About from "./Components/About";
import Services from "./Components/Services/Services";
import Trabajos from "./Components/Trabajos";
import Contacto from "./Components/Contacto";
import ScrollToTop from "./helpers/ScrollToTop";
import WhatsAppLogo from "./helpers/WhatsAppLogo";
import { useTransition, animated } from "react-spring";

// make app responsive
// - navbar ✅
// - font in hero banner ✅
// - specially services
// - add email functionality ✅

export default function App(props) {
  const transitions = useTransition(props.location, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 400 },
  });

  return (
    <div>
      <Route path="/" render={(props) => <ScrollToTop {...props} />} />
      <Route path="/" render={(props) => <WhatsAppLogo {...props} />} />
      <Header />
      {transitions((props, item) => {
        return (
          <animated.div style={props}>
            <Switch location={item}>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/services">
                <Services />
              </Route>
              <Route path="/trabajos">
                <Trabajos />
              </Route>
              <Route path="/contacto">
                <Contacto />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </animated.div>
        );
      })}
    </div>
  );
}
